<template>
  <div class="integrations">
    <div class="integrations__cards" v-if="hasRight('integrations.view')">
      <div
          v-for="item in integrationTypes"
          :key="item.value"
          class="integrations__card"
          :style="{backgroundColor: item.color}"
          @click="toInt(item.value)"
      >
        <img :width="item.iconTypeWidth" :src="require(`@/assets/img/integrations/types/${item.value}.svg`)" :style="{marginBottom: item.marginIconBottom, marginTop: item.marginIconTop}" alt=""/>
        <p>
          {{ item.name }}
          <v-menu
              top
              offset-y
              open-on-hover
              max-height="156"
              v-if="isExtremist(item.value)"
          >
            <template v-slot:activator="{ on, attrs }">
              <strong v-bind="attrs" v-on="on">*</strong>
            </template>
            <v-list-item class="user-color-status d-flex justify-space-between align-center">
              Социальная сеть признана экстремистской и запрещена на территории Российской Федерации.
            </v-list-item>
          </v-menu>
          <v-menu
              top
              offset-y
              open-on-hover
              max-height="156"
              v-if="item.isBeta"
          >
            <template v-slot:activator="{ on, attrs }">
              <span class="beta-tag" v-bind="attrs" v-on="on">БЕТА</span>
            </template>
            <v-list-item class="user-color-status d-flex justify-space-between align-center pa-3">
              Функционал находится на этапе бета-тестирования, но уже доступен к работе.
              <br>
              При возникновении проблем в работе, можете написать на почту support@evisales.com
              <br>
              или через форму в разделе "Обратная связь".
            </v-list-item>
          </v-menu>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {hasRight} from '@/utils/access/hasRight';

export default {
  name: "MainPage",
  computed: {
    ...mapState("common", ["loading", "tableMeta"]),
    ...mapState("integrations", ["integrationTypes", "extremists"]),
  },
  methods: {
    ...mapActions({
      getIntegrationsPages: 'integrations/getIntegrationsPages',
      sessionIntegrationVK: 'integrations/sessionIntegrationVK',
      sessionIntegrationInst: 'integrations/sessionIntegrationInst',
      createIntegrationTg: 'integrations/createIntegrationTg',
      deleteIntegration: 'integrations/deleteIntegration',
    }),
    hasRight: hasRight,
    isExtremist (v) {
      return this.extremists.some(i => i === v);
    },
    getIntegrations () {
      this.getIntegrationsPages({
        per_page: 8,
        page: 1,
      });
    },
    toInt(v) {
      this.$router.push({name: 'IntegrationsType', params: {id: v}}).catch(() => {
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.beta-tag {
  opacity: 0.7;
  color: inherit;
}
</style>
